import React, { useEffect, useState } from "react";
import "../../css/AboutUs.css";
import FlipBox from "./FlipBox";
// import User1 from '../../images/User1.jpg'
import neural from "../../images/neural.jpg";
import students from "../../images/students.jpg";

import { useSpring, animated } from "@react-spring/web";

export default function AboutUs() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 500);
  }, []);
  const props = useSpring({
    opacity: !loading ? "0" : "1",
  });

  return (
    <>
      {/* <div className='about-us-background'
        style={{
          background: `url(${books})`,
          height: '260vh',
          width: '100vw',
          backgroundSize: 'cover',
          position: 'absolute',
          top: '-100px',
          backgroundPosition: 'center'
        }}>

      </div> */}
      <animated.div className="about-us" style={props}>
        {/* 
          <h1>The developers</h1>
          <div className='flip-boxes'>
              <FlipBox text = {data[0].text} image = {data[0].image}/>
              <FlipBox text = {data[1].text} image = {data[1].image}/>
          </div> */}

        <div className="skillify">
          <h1>About Skillify: Empowering Minds, Igniting Knowledge</h1>
          <p>
            <strong>Welcome to Skillify</strong>, an AI-powered platform that
            transcends borders and empowers individuals worldwide to unlock
            their potential and master any skill they desire. Our journey began
            amidst world-wide protests, most notably Romania, where a group of
            passionate minds came together to revolutionize education and make
            learning accessible to all.
          </p>
        </div>

        <div className="first-flipbox">
          <FlipBox
            title="Harnessing AI for Optimal Learning:"
            text={`Skillify is built on the bedrock of advanced artificial intelligence, which sets us apart from traditional learning methods. 
    Our AI algorithms analyze each learner's needs enabling personalized learning pathways that maximize efficiency. By understanding your unique learning style, 
    Skillify empowers you to master any skill faster and with greater precision.`}
            image={neural}
          />
        </div>
        <div className="second-flipbox">
          <FlipBox
            title="Inspiration from Romania's Educational Landscape:"
            text={`In Romania, amidst social change, we observed the commitment of teachers offering paid lessons to small groups of 5-7 students outside of regular school hours. 
      While this showcased dedication, we recognized the limitations of such arrangements—limited class sizes, fixed schedules, and higher costs for learners.
      Skillify aspires to enhance the learning experience beyond these constraints, providing flexibility and efficiency without compromising on quality.`}
            image={students}
          />
        </div>
        <div className="skillify">
          <h1>Join the Skillify Revolution:</h1>
          <p>
            At Skillify, we are committed to transforming education through the
            power of AI and providing an efficient, cost-effective, and
            comprehensive learning platform. Embrace the future of education and
            unlock your full potential with Skillify. Thank you for being a part
            of Skillify's journey, where we empower lifelong learners to thrive
            and excel in a rapidly evolving world. Together, let's make the
            pursuit of knowledge more efficient and enjoyable than ever before.
          </p>
        </div>

        {/* <Footer /> */}
      </animated.div>
    </>
  );
}
